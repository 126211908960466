import store from "@/store";
const OrbitControlsOuter =  require('three/examples/jsm/controls/OrbitControls');
import { default as Stats }  from 'three/examples/jsm/libs/stats.module';
import { GUI }  from 'three/examples/jsm/libs/dat.gui.module';
const THREE = require('three');
import  { TextureLoaderClass } from './../threejsClasses/index';
import { ObjectLoaderClass } from './../threejsClasses/index';
import { DRACOLoaderClass } from './../threejsClasses/index';
import * as CANNON from 'cannon-es';
class OrbitControls extends OrbitControlsOuter.OrbitControls {
  constructor(camera : any, renderer: any) {
    super(camera, renderer);
  }
}
class ThreejsApiService extends Worker {
    sceneChildren: any = [];
    scene = new THREE.Scene();
    clock = new THREE.Clock();
    camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
    renderer = new THREE.WebGLRenderer({ alpha: true, logarithmicDepthBuffer: true });
    loader = new ObjectLoaderClass();
    dracoLoader = new  DRACOLoaderClass();
    raycaster = new THREE.Raycaster();
    intersects: any[] = [];
    documentId: string = '';
    geometry =  new THREE.TorusGeometry( 10, 3, 16, 100 );
		material = new THREE.MeshStandardMaterial( { color: 0xFF6347 } );
    pointLight = new THREE.PointLight(0xffffff);
    ambientLight = new THREE.AmbientLight(0xffffff);
    hemispherelight = new THREE.HemisphereLight( 0xffffbb, 0x080820, 1 );
    lightHelper = new THREE.PointLightHelper(this.pointLight);
    gridHelper = new THREE.GridHelper(200, 50);
    cubeGeometry = new THREE.BoxGeometry( 10, 10, 10 );
    cubeMaterial = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
    keyboardLayout = new THREE.BoxGeometry( 15, 32, 16 );
    keyboardMaterial = new THREE.MeshStandardMaterial( {color: 0x00ff00} );
    mixer: any;
    mixerSkills: any;
    url = `${location.origin}/img/mydp3.jpg`;
	  torus: any;
    star: any;
    globe: any;
    stats: any;
    skills: any;
    orbitControls:  any;
    keyboard: any;
    action: any = {};
    world = new CANNON.World();
    objectAnimations: any[] = [];
    skillsAnimations: any[] = [];
    skillsAction: any = {};
    pickableObjects:  any[] = [];
    intersectedObject: any;
    originalMaterials: any[] = [];
    highlightedMaterial = new THREE.MeshBasicMaterial({
      wireframe: true,
      color: 0x00ff00
    });
    isAllowOrbitControls: boolean = false;
    
    constructor() {
      super('');
    } 


    setDocumentId() {
      store.commit('AppMainStoreModule/setthreejsStatus', true);
    }
    
    start() {
      this.world.gravity.set(0, -9.82, 0);
      this.stats = Stats();
      // const gui: any = new GUI();
      // const physicsFolder: any = gui.addFolder('Physics')
      // physicsFolder.add(this.world.gravity, 'x', -10.0, 10.0, 0.1)
      // physicsFolder.add(this.world.gravity, 'y', -10.0, 10.0, 0.1) 
      // physicsFolder.add(this.world.gravity, 'z', -10.0, 10.0, 0.1)
      // physicsFolder.open()
      const textureLoader = new TextureLoaderClass(`${this.url}`);
      let texture: any = {};
      this.documentId = 'customthreejsComponentID';
      this.dracoLoader.setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/');
      this.dracoLoader.setDecoderConfig({type: 'js'});
      this.loader.setDRACOLoader(this.dracoLoader);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.shadowMap.enabled = true;
      // this.renderer.setClearColor( 0xffffff, 0);
      let appMainId = document.getElementById(this.documentId);
      appMainId && appMainId.appendChild( this.renderer.domElement );
      this.torus = new THREE.Mesh( this.geometry, this.material );
      this.pointLight.position.set(5,25,-5);
      // this.scene.add(new THREE.AxesHelper(5));
      // this.scene.add(this.torus);
      // this.scene.add(this.pointLight, this.ambientLight);
      this.scene.add(this.pointLight, this.hemispherelight);
      // this.scene.add(this.lightHelper, this.gridHelper);
      this.loadObjects('computer22').then((object: any) => {
        this.globe = new THREE.Mesh( this.cubeGeometry, this.cubeMaterial );
        this.globe = object.scene;
        this.mixer = new THREE.AnimationMixer(this.globe);
        // this.action.cdplayer = this.mixer.clipAction(object.animations[2]);
        //  this.action.cpu = this.mixer.clipAction(object.animations[1]);
        this.objectAnimations = object.animations;
        
        this.objectAnimations.forEach((animation: any) => {
          this.action[animation.name] = this.mixer.clipAction(animation);
        });  
        // this.action.cd = this.mixer.clipAction(object.animations[3]);
        // this.action.hello.setEffectiveWeight(1);
        // this.action.cpu.setLoop(THREE.LoopOnce, 0);
        // this.action.hello.clampWhenFinished = true;
        this.objectAnimations.forEach((objectAnimation: any) => {
          this.action[objectAnimation.name].enabled = true;
        });
          
        // this.action.cpu.enabled = true;
        
        // this.action.cpu.play();
        textureLoader.loadImage().then((response: any) => {
          texture = response;
          if (texture.image) {
            for (let index = 0; index < this.globe.children.length; index ++){
              if (this.globe.children[index].name === 'computer') {
                texture.name = 'mydp1';
                // texture.flipY = false;
                for (let x = 0 ;x < this.globe.children[index].children.length; x ++) {
                  for(let y = 0;y < this.globe.children[index].children[x].children.length; y++) {
                    this.globe.children[index].children[x].children[y].material.map = texture;
                    break;
                  }
                }
              }
            }
          }
          this.globe.position.setY(0);
          this.globe.position.setZ(0);  
          this.globe.position.setX(0);
          this.globe.scale.x = 1;
          this.globe.scale.y = 1;
          this.globe.scale.z = 1;
          
          this.scene.add(this.globe);
          console.log(this.globe);
          this.objectAnimations.forEach((objectAnimation: any) => {
            this.action[objectAnimation.name].play();
          });
        });
      })
      .catch((error: any) => {
        console.warn(error);
      })
      .finally(() => {
        let appMainId = document.getElementById(this.documentId);
        appMainId && appMainId.addEventListener('dblclick', (event: MouseEvent) => {
          this.onPickElements(event);
        }, false);
        appMainId && appMainId.addEventListener('mousemove', (event: MouseEvent) => {
          this.onSelectElemets(event);
        }, false);
        this.orbitControls = new OrbitControls( this.camera, this.renderer.domElement);
        this.camera.position.setZ(-15);
        this.camera.position.setY(5);
        this.camera.position.setX(0);
        appMainId && this.animate();
        setTimeout(() => {
          store.commit('AppMainStoreModule/setLoadingStatus', true);
        }, 6000);
      });
        
    }
    animate() {
      let self = this;
      window.requestAnimationFrame(() => { self.animate() });
      var delta = this.clock.getDelta();
      this.world.step(delta);
      this.mixer && this.mixer.update(delta);
      this.mixerSkills && this.mixerSkills.update(delta);
      this.orbitControls.update();
      this.stats && this.stats.update();
      this.renderer.render(this.scene, this.camera);
    }
    removeEntity() {
      // var selectedObject = this.scene.getObjectByName(object.name);
      this.scene.dispose(this.globe);
      this.animate();
    }
    stop() {
      let appMainId = document.getElementById(this.documentId);  
      appMainId && appMainId.removeChild( this.renderer.domElement );
    }
    changeAnimationStatus(status: boolean) {
      store.commit('AppMainStoreModule/setAnimationStatus', status);
      if (status) {
        this.objectAnimations.forEach((objectAnimation: any) => {
          this.action[objectAnimation.name].play();
        });
      } else {
        this.objectAnimations.forEach((objectAnimation: any) => {
          this.action[objectAnimation.name].stop();
        });
      }
    }
    disposeObjects() {
      this.renderer.renderLists.dispose();
    }
    disableOrbitalControl(value: boolean) {
      this.orbitControls.enabled = value ? value : false;
    }
    updateCameraPosition(axis: string, value: number) {
      switch(axis){
        case 'x': this.camera.position.setX(value);
                 break;
        case 'y': this.camera.position.setY(value);
                 break;
        case 'z': this.camera.position.setY(value);
                 break;                  
      }
      this.orbitControls.update();
    }
    loadObjects(object: string) {
      return new Promise(async (s, r) => {
        await this.loader.load(
          `${location.origin}/glbs/${object}.glb`,
          (gltf: any) => {
            s(gltf);   
        },undefined,(error:any) => {
            r(error);
        });
      });
    }
    onPickElements(event: MouseEvent) {
      this.raycaster.setFromCamera({
          x: (event.clientX / this.renderer.domElement.clientWidth) * 2 - 1,
          y: -(event.clientY / this.renderer.domElement.clientHeight) * 2 + 1
      },
      this.camera
    )
    this.intersects = this.raycaster.intersectObjects(this.globe.children, false);
    if (this.intersects.length > 0) {
      this.intersectedObject = this.intersects[0].object;
    } else {
      this.intersectedObject = null;
    }
    this.pickableObjects.forEach((o: any, i) => {
      if (this.intersectedObject && this.intersectedObject.name === o.name) {
        this.globe.children[i].material = this.highlightedMaterial;
      } else {
        this.globe.children[i].material = this.originalMaterials[o.name];
      }
    });
  }
  showStats(isShowStats: boolean = false) {
    if (isShowStats) {
      const statWrapper = document.getElementById('statWrapper');
      if (statWrapper) {
        this.stats.dom.style.position = 'absolute';
        this.stats.dom.style.left= '50px';
        statWrapper.appendChild(this.stats.dom);
      }
    } else {
      const statWrapper = document.getElementById('statWrapper');
      if (statWrapper?.hasChildNodes) {
        statWrapper.removeChild(this.stats.dom);
      }
    }
  }
  onSelectElemets(event: MouseEvent) {
    if (this.intersectedObject) {
      let clientX = 0;
      let clientY = 0;
      clientX =  (event.clientX / this.renderer.domElement.clientWidth) * 2 - 1;
      clientY = -(event.clientY / this.renderer.domElement.clientHeight) * 2 + 1;
      for (let i = 0 ; i < this.globe.children.length; i += 1) {
        if (this.intersectedObject.name === this.globe.children[i].name &&
            this.intersectedObject.name !== 'screen') {
          this.globe.children[i].position.x = -clientX;
          this.globe.children[i].position.y = -clientY;
        }
      }
    }
  }
}

export default new ThreejsApiService();