
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ToggleSwitch from './toggleSwitch.component.vue';
import ThreejsApiService from './../../../shared/threejs/threejs.api';
import store from "@/store";
const cssPrefix = 'settings';
@Options({
  components: {
    ToggleSwitch
  },
})
export default class SettingsModal extends Vue {
  cssPrefix = cssPrefix;
  @Prop()
  openModal: boolean = false;

  isShowStat: boolean = false;
  stopAnimations: boolean = false;

  get isStopAnimations(): boolean {
    return store!.state.AppMainStoreModule.animationStatus ? true : false;
  }

  onCloseSettings() {
    this.$emit('close-modal');  
  }
  stopMainAnimations(value: boolean) {
    ThreejsApiService.changeAnimationStatus(value);
    // ThreejsApiService.disableOrbitalControl(false);
  }
  onChangeSwitch(value: any) {
    if (value) {
      this.stopMainAnimations(false);
    } else {
      this.stopMainAnimations(true);
    }
  }
  onShowStats() {
    this.isShowStat = !this.isShowStat;
    ThreejsApiService.showStats(this.isShowStat);
  }
}
