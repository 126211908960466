const THREE = require('three');
export default class TextureLoaderClass {
  public urlPath: string = '';
  constructor(url: string) {
    this.urlPath = url;  
  }
  

  loadImage() {
    return new Promise(async (s, r) => {
      const textureLoader = new THREE.TextureLoader();
      let t = await textureLoader.load(this.urlPath);
      setTimeout(() =>  { s(t); } , 2000);
    });
  }
} 