
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
const cssPrefix = 'toggleSwitch'; 
export default class ToggleSwitch extends Vue {
  cssPrefix = cssPrefix;  
  @Prop()
  value: boolean = false;
  
  enabled: boolean = false;
   
  enableSwitchAnimation: string = ''; 

  mounted() {
    this.enabled = this.value;
    this.enableSwitchAnimation = ''; 
  }

  onEnable() {
    if (this.enabled) {
      this.enableSwitchAnimation = 'offAnimation';
      setTimeout(() => {
       this.enabled = !this.enabled;
       this.$emit('changeSwitch', this.enabled); 
      }, 1);
    } else if (!this.enabled) {
      this.enableSwitchAnimation = 'onAnimation';
      setTimeout(() => {
       this.enabled = !this.enabled;
       this.$emit('changeSwitch', this.enabled); 
      }, 1);
    }
    
  }
}
