export default {
    install: (app, options) => {
      app.config.globalProperties.$translate = (key) => {
        return key.split('.')
          .reduce((o, i) => { if (o) return o[i] }, options)
      }
  
      app.provide('vue-i18n', options)
  
      app.directive('my-directive', {
        mounted (el, binding, vnode, oldVnode) {
          // some logic ...
        }
      })
      app.mixin({
        created() {
          // some logic ...
        }
      })
    }
  }