import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/scss/main.scss';
import i18nPlugin from './plugins/i18n';
import { Locales } from './assets/locales';
import VueWorker from './plugins/vueWorker';


let app = createApp(App).use(store).use(i18nPlugin, Locales).use(VueWorker, '$worker').use(router).mount('#app');

export default app;