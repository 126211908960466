<template>
   <router-view/>
</template>

<style lang="scss">
#app {
   padding:0;
   margin:0;
}
</style>
<script>
import AppMainComponent from './core/app-main-component.vue';
import { Options, Vue } from 'vue-class-component';
@Options({
  components: {
    AppMainComponent,
  },
})
export default class App extends Vue {
} 
</script>
