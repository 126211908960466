import Vuex,{ createStore, Store } from 'vuex';

import { AppMainStoreModule, CoreStoreState } from '../core';
export interface RootStoreState {
  [modules: string] : any,
  AppMainStoreModule: CoreStoreState,
}

const store = createStore<RootStoreState>({
  modules: {
    AppMainStoreModule,
  }
})

export default store;
export type AppStore = Store<RootStoreState>;