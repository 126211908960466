<template>
  <div id="customthreejsComponentID"> 
  </div>
</template>
<style>
#customthreejsComponentID{
  width:100vw;
  height:100vh;
  position:absolute;
  top:0px;
  left:0px;
}
</style>
<script>
  import { Vue, Options } from 'vue-class-component';
  Options({
  })
  export default class CustomThreejsComponent extends Vue {
  }
</script>
