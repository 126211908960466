import { RootStoreState } from "@/store";
import { StaticReadUsage } from "three";
import { ActionContext, Module } from "vuex";

export interface CoreStoreState {
  enableThreejs: boolean;
  isObjectsLoaded: boolean;
  widthMode: string;
  animationStatus: boolean;
}

export default {
  state:{
    enableThreejs: false,
    isObjectsLoaded: false,
    widthMode: 'desktop3',
    animationStatus: true,
  },
  getters:{
    getThreejsStatus: state => state.enableThreejs,
    getObjectsLoadedInfo: state => state.isObjectsLoaded,
  },
  actions:{
    async setUpThreejs(context: ActionContext<CoreStoreState, RootStoreState>, payload: any) {
      context.commit('setthreejsStatus', payload);
      let status: any;
      if (context.getters.getThreejsStatus) {
        return status = await Promise.resolve("success");
      }
      return status = await Promise.resolve("failed");
    }
  },
  mutations:{
    setthreejsStatus(state: CoreStoreState,payload: boolean) {
      state.enableThreejs = payload;
    },
    setLoadingStatus(state: CoreStoreState,payload: boolean) {
      state.isObjectsLoaded = payload;
    },
    setDeviceConfig(state: CoreStoreState, payload: string) {
      state.widthMode = payload;
    },
    setAnimationStatus(state: CoreStoreState, payload: boolean) {
      state.animationStatus = payload;
    }
  },
  namespaced:true,
} as Module<CoreStoreState, RootStoreState>;
