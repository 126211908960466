
import { Options, Vue } from 'vue-class-component';
import store from "@/store";
import  ThreejsApiService  from './../../shared/threejs/threejs.api';
const cssPrefix = 'home';
@Options({
  components: {
  },
})
export default class Home extends Vue {
  cssPrefix = cssPrefix;
  xValue: string = '';
  yValue: string = '';
  zValue: string = '';
  showInfoAnimations: boolean = false;
  showName: boolean = false;
  showProfileInfo: boolean = false;
  showContactLinks: boolean = false;
  TITLE_ANIMATION_TIME1: number = 2000;
  profileInfoHeight: number = 0;
  titleName: string = '';
  titleWish: string = '';
  titleMapping: any[] = ['Hi,' , 'I\'m', 'Akshay Krishna'];
  mounted() {
    store.dispatch('AppMainStoreModule/setUpThreejs', true)
      .then(async (result : any) => {
        if (result === 'success') {
          this.$nextTick(() =>  {        
            ThreejsApiService.start();
          });
        }
      });
    setTimeout(()=> {
      ThreejsApiService.changeAnimationStatus(false);
      this.addAnimationForIntro();
    }, 10000);  
  }
  destroy() {
    ThreejsApiService.disposeObjects();
  }
  onChangeCameraPositionX(event: any){
    let value = event.target.value;
    ThreejsApiService.updateCameraPosition('x', +value);
  }
  onChangeCameraPositionY(event: any) {
    let value = event.target.value;
    ThreejsApiService.updateCameraPosition('y', +value);
  }
  onChangeCameraPositionZ(event: any) {
    let value = event.target.value;
    ThreejsApiService.updateCameraPosition('z', +value);
  }
  get contactsIconWidth(): number{
    return 30;
  } 
  get contactsIconHeight(): number{
    return 30;
  }
  addAnimationForIntro() {
    setTimeout(()=> {
      this.showInfoAnimations = true;
      this.getIntro();
    }, 200);
  }
  private getAnimation(type: string) {
    let selectedAnimation: string = '';
    if (this.showInfoAnimations) {
      switch(type) {
        case 'profileInfo': 
          if (this.showProfileInfo) {
            selectedAnimation = 'profileInfoAnimation';
          }
          break;
        case 'contactsLink':
          if (this.showContactLinks) {
             selectedAnimation = 'contactLickAnimation';
          }
          break;               
      }
    } 
    return selectedAnimation;
  }
  private getIntro() {
    if (this.showInfoAnimations) {
      let count = 0;
      const titleAni = setInterval(() => {
        if (count >= this.titleMapping.length -1) {
          this.showName = true;
          this.startProfileInfo();
          clearInterval(titleAni);
          return;
        }
        this.titleWish = `${this.titleWish} ${this.titleMapping[count]}`;
        count++;
      }, this.TITLE_ANIMATION_TIME1);   
    }
  } 
  private startProfileInfo() {
    setTimeout(() => {
      this.titleName = `${this.titleMapping[this.titleMapping.length -1]}`
      this.profileInfoHeight = (this.$refs.avatarWrapper as HTMLDivElement).offsetHeight || 0;
      this.$nextTick(() =>  {        
        this.showProfileInfo = true;
        this.showContactLinks = true;
      });
    }, this.TITLE_ANIMATION_TIME1);
  }
}
