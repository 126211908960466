
import { Vue,  Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
const cssPrefix = 'appHeader';
import SettingsModal from './../../modules/home-component/components/settings.modal.vue';
@Options({
  components: {
    SettingsModal,
  },
})
export default class AppHeaderComponent extends Vue {
  cssPrefix = cssPrefix;
  openModal: boolean = false;
  selectedDeviceConfig: string = '';
   
  @Prop()
  deviceConfig: string = '';
  
  @Watch('deviceConfig')
  private onChangeDeviceConfig() {
    this.selectedDeviceConfig = this.deviceConfig ? this.deviceConfig : '';
  }

  getDeviceConfig(): string {
    return this.selectedDeviceConfig;
  }

  created() {
    this.selectedDeviceConfig = this.deviceConfig ? this.deviceConfig : '';
  }

  onOpenSettings() {
    this.openModal = !this.openModal;
  }
  closeSettings() {
    this.openModal = !this.openModal;
  }
  isActiveRoute(path: string) {
    return this.$route.fullPath.indexOf(path) > -1 ? true : false;
  }
  checkDevice(device: string) {
    return this.selectedDeviceConfig.indexOf(device) > -1 ? true :false;
  }
  navigate(path: string) {
    if (path === 'contacts') {
      this.$emit('open-contact', true); 
    } else {
      this.$router.push(path);
    }
  }
}
