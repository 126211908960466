
import { Vue, Options } from 'vue-class-component';
import store from "@/store";
import  AppHeaderComponent from './components/app-header-component.vue';
import  AppFooterComponent from './components/app-footer-component.vue';
import  AppContentComponent from './components/app-content-component.vue';
import CustomThreejsComponent from './../shared/threejs/CustomThreejs.vue';
import ThreejsApiService from './../shared/threejs/threejs.api';
import LoadingScreen from './components/app-loading-page.vue';
const cssPrefix = 'appMain';
@Options({
  components: {
    AppHeaderComponent,
    AppFooterComponent,
    AppContentComponent,
    CustomThreejsComponent,
    LoadingScreen,
  },
})
export default class AppMainComponent extends Vue {
  cssPrefix = cssPrefix;
  theTopValue: number = 0;
  isShowContact: boolean = false;
  
  get getTopValue() {
     return this.theTopValue;
  }
  get getThreejsStatus() {
    return store!.getters!.getThreejsStatus;
  }
  
  get isShowLoadingScreen() {
    return store!.state.AppMainStoreModule.isObjectsLoaded ? true : false;
  }

  get deviceConfig() {
    return store!.state.AppMainStoreModule.widthMode;
  }

  isAllowthreejsBasedRoutes() {
    const allroutes = ['home', 'skill'];
    return allroutes.filter((ele) => {
      return this.$route.fullPath.indexOf(ele) > -1;
    }).length > 0 ? true : false;  
  }
  mounted() {
    this.onCalculateWidth();
    window.addEventListener('resize', () => {
      this.onCalculateWidth(); 
    });
    const da2 = document.getElementById('appMainId');
    da2 && da2.addEventListener('scroll', (event: any) => {
      this.theTopValue = event.target.scrollTop;
    })
    this.$router.push('/home');
  }
  showcontact(value: any) {
    if (value) {
      this.isShowContact = true;
      ThreejsApiService.changeAnimationStatus(false);
    } else {
      this.isShowContact = false;
    }
  }
  onCalculateWidth() {
    const width = window.innerWidth;
    if (width <= 320) {
      store.commit('AppMainStoreModule/setDeviceConfig', 'phone1'); 
    }
  }
}
