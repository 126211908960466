import { createRouter, createWebHistory } from 'vue-router';
import AppMainComponent from './../core/app-main-component.vue';
import { HomeRouteModule } from './../modules/home-component';


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes : [{
    path: `${process.env.BASE_URL}/`,
    component: AppMainComponent,
    children:[
      {...HomeRouteModule}
    ]
  },{
    path: '',
    redirect: '/home',
  },],
})

export default router;
